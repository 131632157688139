.custom-dialog-panel app-overlay {
    @apply flex p-0 rounded-xl bg-transparent;
    max-height: inherit;
    min-height: inherit;
    box-shadow:
        0px 2px 3px -2px rgba(0, 0, 0, 0.2),
        0px 2px 4px 0px rgba(0, 0, 0, 0.14),
        0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
    & > * {
        @apply rounded-xl surface-bg-color text-dark h-full;
        border: 1px solid rgba(0, 0, 0, 0.04);
    }
}
.custom-dialog-container .mat-dialog-container {
    @apply flex p-0 rounded-xl overflow-hidden bg-transparent;
    > * {
        @apply flex flex-col flex-grow max-h-full border secondary-border-color text-dark rounded-xl surface-bg-color;
        .mat-dialog-header {
            @apply w-full flex items-center justify-between py-5 px-6 z-10 secondary-border-color;
        }
        .mat-dialog-body {
            @apply overflow-y-auto p-6 flex-auto;
        }
        .mat-dialog-footer {
            @apply w-full flex gap-4 items-center justify-between py-5 px-6 z-10 secondary-border-color mt-auto;
        }
    }
}
.blink-dialog {
    animation: blink-border 1s linear;
    @keyframes blink-border {
        20% {
            @apply border-transparent;
        }
        50% {
            @apply border-primary;
        }
        80% {
            @apply border-transparent;
        }
    }
}
.svg-stroke-white svg > * {
    @apply stroke-white;
}
.svg-gold-filter {
    filter: invert(70%) sepia(50%) saturate(1000%) hue-rotate(5deg) brightness(100%) contrast(95%);
}
.svg-primary-filter {
    filter: invert(67%) sepia(31%) saturate(6202%) hue-rotate(205deg) brightness(102%) contrast(96%);
}

.svg-warning-filter {
    filter: invert(79%) sepia(73%) saturate(5804%) hue-rotate(324deg) brightness(107%) contrast(104%);
}
.svg-white-filter {
    filter: invert(98%) sepia(4%) saturate(2%) hue-rotate(210deg) brightness(120%) contrast(100%);
}
.svg-gray-filter {
    filter: invert(25%) sepia(6%) saturate(16%) hue-rotate(40deg) brightness(95%) contrast(84%);
}
.svg-light-gray-filter {
    filter: invert(72%) sepia(18%) saturate(18%) hue-rotate(44deg) brightness(89%) contrast(84%);
}
.svg-white-filter {
    filter: invert(90%) sepia(39%) saturate(0%) hue-rotate(155deg) brightness(106%) contrast(103%);
}
.no-scrollbar::-webkit-scrollbar {
    @apply hidden;
}
.border-highlighter {
    border: 1px solid rgba(0, 0, 0, 0.04);
}

.animate-catch-attention {
    animation: catch-attention 1s ease-in-out;
    @keyframes catch-attention {
        0% {
            transform: translateY(0);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        }
        25% {
            transform: translateY(-25%);
            animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
        }
        50% {
            transform: translateY(0);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        }
        75% {
            transform: translateY(-20%);
            animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
        }
        100% {
            transform: translateY(0);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        }
    }
}

/* --------------------------- simplified tooltip --------------------------- */
[customtooltip] {
    position: relative;
    &:hover::after {
        content: attr(customtooltip);
        z-index: 10;
        position: absolute;
        top: -1rem;
        left: 50%;
        transform: translate(-50%, -100%);
        white-space: nowrap;
        background: #505050;
        padding: 4px 6px;
        color: white;
        border-radius: 4px;
        font-size: 9px;
        font-weight: 400;
        opacity: 0;
        animation-name: tooltip-showup;
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
        animation-delay: 500ms;
        @keyframes tooltip-showup {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }
}

[customtooltip][tooltip-above]::after {
    top: -1rem;
    left: 50%;
    transform: translate(-50%, -100%);
}

[customtooltip][tooltip-below]::after {
    top: unset;
    bottom: 1rem;
    left: 50%;
    transform: translate(-50%, 100%);
}
.sticky-search {
    position: sticky;
    top: 0px;
    z-index: 11211;
}

.drawer-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    visibility: hidden;
    top: 0;
    left: 0;
    z-index: 1004;
    opacity: 0;
    background: rgba(#0f1526, 0.54);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;

    &.show {
        opacity: 1;
        visibility: visible;
    }

    &.hidden {
        visibility: hidden;
    }
}

.deal-opt {
    border-bottom: 1px solid #EAECF0;
}

.custom-table {
    box-shadow: var(--main-sections-box-shadow);

    border-spacing: 0 1rem !important;
    border-collapse:separate;
    margin-right: -2rem;

    th.mat-header-cell,
    td.mat-cell,
    td.mat-footer-cell {
        padding: 1rem !important;
        white-space: nowrap;
        height: 5rem;
    }
    td.mat-cell {
        @apply secondary-border-color border-t border-b;
    }
    td.mat-cell:first-of-type {
        @apply secondary-border-color border-l rounded-tl-lg rounded-bl-lg;
    }
    td.mat-cell:last-of-type {
        @apply secondary-border-color border-r rounded-tr-lg rounded-br-lg;
    }
    th.mat-header-cell {
        padding: 0 1.25rem !important;
        height: 3rem;
        background: #F9FAFB;
    }
    tr.mat-row {
        &:hover {
            @apply highlight-bg-color;
        }
        &.active {
            @apply highlight-bg-color;
        }
    }
}

@media screen and (max-width: 1485px) {
    .products-empty-label {
        gap: 0px !important;
        .cont-label {
            gap:0.5 rem !important;
        }
        app-button {
            width: 80px !important;
        }
    }
}

@media screen and (max-width: 1380px) {
    .products-empty-label {
        .cont-label {
            gap:0.5 rem !important;
        }
        app-button {
            width: 85px !important;
        }
    }
}


.input-style {
    @apply p-4 rounded border-2 bg-transparent flex-1 w-full outline-none focus-within:border-primary secondary-border-color ring-transparent;
    &.invalid {
      @apply border-red-500;
    }
    height: 3.25rem;
}
#import-companies {
    div.drawer-body {
        max-width: 1200px !important;
        margin: 0 auto !important;
    }
}

.yob-btn {
    padding: 8px;
    height: 30px;
    border-radius: 8px;
    background: #674deb;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    @apply text-white;
    transition: all 0.3s ease;
    
    &:hover {
        transform: scale(1.05);
        background: #5a43d9;
        box-shadow: 0 4px 12px rgba(103, 77, 235, 0.25);
    }

    &:active {
        transform: scale(0.98);
    }
}
.cal-today {
    background: #F5F0FF !important;
}