:root {
    --primary-color: #674deb;
    --plyr-range-fill-background: var(--primary-color);
    --plyr-color-main: var(--primary-color);
    --plyr-color-main: var(--primary-color);
    // --main-sections-gap: 0.75rem;
    // --main-sections-border-radius: 0.7rem;
    // --main-sections-box-shadow: 0px 1px 3px 0 rgb(0 0 0 / 0.06), 0px 1px 2px 0 rgb(0 0 0 / 0.12);
    // --main-sections-border: none;
    
    --main-sections-gap: 0 !important;
    --main-sections-border-radius: 0 !important;
    --main-sections-box-shadow: none !important;
    --main-sections-border: var(--devider-border) !important;

    --header-height: 3.75rem;
    --devider-border: 1px solid #e8eaed;
    .dark {
        --devider-border: 1px solid #293341;
    }
}

@media (max-width: 1536px) {
    // tailwind css 2xl screen
    :root {
        // --main-sections-gap: 0.6rem;
        // --main-sections-border-radius: 0.6rem;
        --main-sections-border: none;
        --header-height: 3.5rem;
    }
}

@media (max-width: 1279px) {
    // tailwind css lg screen
    :root {
        // --main-sections-gap: 0.5rem;
        // --main-sections-border-radius: 0.5rem;
        --main-sections-border: none;
        --header-height: 3.25rem;
    }
}

@media (max-width: 767px) {
    // tailwind css sm screen
    :root {
        // --main-sections-gap: 0;
        // --main-sections-border-radius: 0;
        // --main-sections-box-shadow: none;
        --main-sections-border: var(--devider-border);
        .dark {
            --main-sections-border: var(--devider-border);
        }
    }
}

.compact-mode {
    // --main-sections-gap: 0 !important;
    // --main-sections-border-radius: 0 !important;
    // --main-sections-box-shadow: none !important;
    // --main-sections-border: var(--devider-border) !important;
    .dark {
        --main-sections-border: var(--devider-border) !important;
    }
}
