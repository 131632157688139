/* ---------------------------- Angular material ---------------------------- */
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@include mat.core();
$Yobi-app-primary: mat.define-palette(mat.$indigo-palette);
$Yobi-app-accent: mat.define-palette(mat.$indigo-palette, 500, A100, A400);
$Yobi-app-warn: mat.define-palette(mat.$red-palette);
$Yobi-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $Yobi-app-primary,
      accent: $Yobi-app-accent,
      warn: $Yobi-app-warn
    )
  )
);
@include mat.all-component-themes($Yobi-app-theme);

@import "angular-calendar/css/angular-calendar.css";

/* ------------------------------ styles files ------------------------------ */
@import "styles.material-components.scss";
@import "styles.global-classes.scss";
@import "styles.tailwindcss.scss";
@import "styles.variables.scss";

/* ----------------------------- ngx-emoji-mart ----------------------------- */
@import "~@ctrl/ngx-emoji-mart/picker";

/* ---------------------------------- fonts --------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap");
@font-face {
  font-family: lato;
  src: url(../assets/fonts/SF-Pro-Display-Regular.otf) format("opentype");
}

* {
  font-family: "Inter", lato;
}

/* ----------------------------- more libreries ----------------------------- */
@import "~@angular/cdk/overlay-prebuilt.css";
@import "~viewerjs/dist/viewer.css";
@import "~ngx-toastr/toastr";
@import '~codemirror/lib/codemirror';
@import '~codemirror/theme/material';

input:-webkit-autofill {
  -webkit-background-clip: text;
}

/* --------------------------- Scrollbar styling --------------------------- */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #8E8E93 transparent;
}

/* Chrome, Edge, Safari */
::-webkit-scrollbar {
  width: 16px;
  background: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #8E8E93;
  border-radius: 100px;
  border: 4px solid transparent;
  background-clip: padding-box;
  min-height: 50px;

  &:hover {
    background-color: #636366;
    border: 3px solid transparent;
  }

  &:active {
    background-color: #48484a;
    border: 2px solid transparent;
  }
}

/* Dark mode */
.dark {
  * {
    scrollbar-color: #636366 transparent;
  }
  
  ::-webkit-scrollbar {
    background: transparent;
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #636366;
    border-color: transparent;
    
    &:hover {
      background: #48484a;
      border-color: transparent;
    }
    
    &:active {
      background: #3a3a3c;
      border-color: transparent;
    }
  }
}

/* ----------------------------- global styling ----------------------------- */
html,
body {
  margin: 0;
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
  &.drawer-open {
    overflow: hidden;
    }
}

* {
  font-size: 0.9rem;
  box-sizing: border-box !important;
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
  // font-weight: 450;
}

$gradient: linear-gradient(225deg, #d665ff 0%, #4c6fff 100%) !important;
.bg-gd {
  background: $gradient;
}

.mentioned-user {
  color: #00b4d8 !important;
  background-color: #00b4d82f !important;
}

.dark {
  input {
    color-scheme: dark;
  }
}

td.hotkeys-table-help-shortcut-description {
  width: 70%;
}

.Papercups-toggleButtonContainer {
  @apply shadow-md rounded-full;
  button {
    background: $gradient;
  }
  @media (max-width: 640px) {
    button {
      height: 45px;
      width: 45px;
      svg {
        transform: scale(0.8);
      }
    }
  }
}

/* ------------------------ highlight section effect ------------------------ */
.highlighted {
  animation: _highlighted 3.5s ease;
  @keyframes _highlighted {
    0% {
      box-shadow: 0 0 0 1px transparent;
    }
    33% {
      box-shadow: 0 0 0 3px #ffbf0048;
    }
    100% {
      box-shadow: 0 0 0 3px transparent;
    }
  }
}

/* ------------------------------ emoji picker ------------------------------ */
emoji-mart {
  .emoji-mart-bar {
    @apply secondary-border-color;
  }
  .emoji-mart-search {
    @apply border flex items-center mx-2 px-0 rounded secondary-border-color text-dark mb-2;
    input {
      @apply bg-transparent border-0 flex-1 outline-none ring-0;
    }
    button {
      top: 6px;
      right: 6px;
      svg path {
        fill: currentColor;
      }
    }
  }
  .emoji-mart-category-label span {
    @apply surface-bg-color text-mild;
  }
  span.emoji-mart-emoji {
    &:hover:before {
      @apply cursor-pointer secondary-bg-color;
    }
    span {
      cursor: pointer !important;
    }
  }
}

/* ----------------------------- mention picker ----------------------------- */
app-formatted-textarea {
  .dropdown-menu.mention-menu {
    @apply surface-bg-color border secondary-border-color rounded p-2 shadow-md;
    &::-webkit-scrollbar {
      display: none;
    }
    li {
      @apply py-1 cursor-pointer hover:light-bg-color;
      &.mention-active {
        @apply light-bg-color;
        > a {
          background: transparent !important;
          p {
            @apply text-dark;
          }
        }
      }
    }
  }
  .active-mention-member::after {
    @apply absolute bg-success rounded-full;
    content: "";
    height: 0.75rem;
    width: 0.75rem;
    left: 1.2rem;
    top: 1.2rem;
  }
  #text-area-footer {
    font-size: 12px !important;
    .mention {
        font-size: 12px !important;
    }
  }
}

/* -------------- TODO verify clean and move to ./material.scss ------------- */

.overlay-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.templateBackDrop {
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.12),
    0 3px 6px rgba(0, 0, 0, 0.18);
  border-radius: 10px;
  overflow: hidden;
}

.custom-dialog-backdrop {
  transition: all 0.2s ease-in;
}

.custom-transparent-dialog-panel {
  // pointer-events: none;
  .mat-dialog-container {
    box-shadow:
      0 12px 68px rgb(0 0 0 / 10%),
      0 32px 100px rgb(0 0 0 / 10%);
    border-radius: 1.25rem !important;
    overflow-y: auto !important;
  }
}
.text-content {
    .mention {
         padding: 3px 8px;
         background: #0000001f;
         border-radius: 4px;
         line-height: 1;
         text-decoration: dashed;
         cursor: pointer;
         color: #007ff9;
         font-size: 0.95em;
         margin-inline-end: 4px;
    }
}

// Tour highlight styles
.tour-highlight {
  position: relative !important;
  z-index: 9999 !important;
  animation: tour-pulse 2s ease-in-out infinite !important;
  transition: all 0.3s ease-in-out !important;
}

@keyframes tour-pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(66, 133, 244, 0.7);
  }
  
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 20px rgba(66, 133, 244, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(66, 133, 244, 0);
  }
}