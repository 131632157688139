.mat-checkbox-frame {
    @apply secondary-border-color;
}
// Mat menu ----------------------------------------------------
.mat-menu-panel {
    @apply surface-bg-color border secondary-border-color;
    border-radius: 8px !important;
    box-shadow: 0px 2px 3px -2px rgb(0 0 0 / 20%), 0px 2px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%) !important;
    min-height: 48px !important;
    max-height: 300px !important;
    .mat-menu-content {
        @apply surface-bg-color;
        padding: 0 !important;
        .mat-menu-item {
            @apply text-dark;
        }
    }
    .mat-menu-item:hover:not([disabled]),
    .mat-menu-item.cdk-program-focused:not([disabled]),
    .mat-menu-item.cdk-keyboard-focused:not([disabled]),
    .mat-menu-item-highlighted:not([disabled]) {
        background: rgba($color: #000000, $alpha: 0.1);
    }
}
// Mat radio --------------------------------------------------
.mat-radio-outer-circle {
    @apply secondary-border-color;
}
// Mat tooltip --------------------------------------------------
.mat-tooltip {
    background: #444444 !important;
    font-size: 14px;
}
.shortcut-tooltip {
    font-size: 11px !important;
    letter-spacing: 1px;
    &::after {
        content: "";
        padding: 0.25rem 0.5rem;
        background: #e6e6e6;
        margin-left: 0.5rem;
        color: #333333;
        border-radius: 0.25rem;
    }
    &.reply::after {
        content: "R";
    }
    &.note::after {
        content: "N";
    }
    &.task::after {
        content: "T";
    }
    &.emoji::after {
        content: ";";
    }
    &.send-mac::after {
        content: "Command + Return";
    }
    &.send::after {
        content: "Ctrl + Enter";
    }
    &.toggle-menu::after {
        content: "(";
    }
    &.toggle-right-side-panel::after {
        content: ")";
    }
}
// cdk-virtual-scroll-viewport
.cdk-virtual-scroll-viewport {
    contain: style !important;
}

// Mat paginator --------------------------------------------------------------
mat-paginator {
    .mat-paginator-container {
        min-height: unset !important;
    }
}
// Mat tab --------------------------------------------------------------------
mat-tab-group {
    .mat-tab-label {
        padding: 0 12px;
        &:not(.mat-tab-disabled) {
            opacity: 1 !important;
            &:focus {
                @apply text-primary;
            }
        }
    }
    &.mat-tab-group.mat-primary .mat-ink-bar {
        @apply bg-primary;
    }
}
.mat-tab-header-pagination-chevron {
    @apply secondary-border-color;
}
/* -------------------------------- mat table ------------------------------- */

mat-table {
    @apply highlight-bg-color text-dark;
    .mat-cell, .mat-footer-cell, .mat-header-cell {
        color: inherit;
    }
    mat-row, mat-header-row, mat-footer-row, th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
        @apply secondary-border-color;
    }
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background  {
    background-color: #7F56D9 !important;
}


.mat-checkbox-checked.mat-orange .mat-checkbox-background  {
    background-color: #FF9C66 !important;
}


.mat-checkbox-checked.mat-blue .mat-checkbox-background  {
    background-color: #36BFFA !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #7F56D9 !important;
}


.mat-slide-toggle.mat-checked .mat-slide-toggle-bar  {
    background-color: #c5b2e2 !important;
}

.toggle {
    color: #475467;
}


.mat-progress-spinner circle, .mat-spinner circle  {
    stroke: #674deb;

}